import React, { useState } from 'react';
import { Menu, X, Wine, DollarSign, Truck, ChevronRight, Phone, Mail, MapPin } from 'lucide-react';

export default function App() {
  const [currentPage, setCurrentPage] = useState('home');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const Navigation = () => (
    <nav className="bg-white border-b">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <span className="text-xl font-bold text-blue-600">BottleCosts</span>
          </div>
          
          <div className="hidden sm:flex sm:items-center space-x-8">
            <button onClick={() => setCurrentPage('home')} 
              className={`px-3 py-2 text-sm font-medium ${currentPage === 'home' ? 'text-blue-600' : 'text-gray-600 hover:text-blue-600'}`}>
              Home
            </button>
            <button onClick={() => setCurrentPage('about')}
              className={`px-3 py-2 text-sm font-medium ${currentPage === 'about' ? 'text-blue-600' : 'text-gray-600 hover:text-blue-600'}`}>
              About
            </button>
            <button onClick={() => setCurrentPage('contact')}
              className={`px-3 py-2 text-sm font-medium ${currentPage === 'contact' ? 'text-blue-600' : 'text-gray-600 hover:text-blue-600'}`}>
              Contact
            </button>
          </div>

          <div className="flex items-center sm:hidden">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-gray-600">
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="sm:hidden p-2">
          <button onClick={() => { setCurrentPage('home'); setIsMenuOpen(false); }}
            className="block px-3 py-2 text-base font-medium text-gray-600 hover:text-blue-600 w-full text-left">
            Home
          </button>
          <button onClick={() => { setCurrentPage('about'); setIsMenuOpen(false); }}
            className="block px-3 py-2 text-base font-medium text-gray-600 hover:text-blue-600 w-full text-left">
            About
          </button>
          <button onClick={() => { setCurrentPage('contact'); setIsMenuOpen(false); }}
            className="block px-3 py-2 text-base font-medium text-gray-600 hover:text-blue-600 w-full text-left">
            Contact
          </button>
        </div>
      )}
    </nav>
  );

  const HomePage = () => (
    <div>
      <div className="relative bg-gradient-to-r from-blue-600 to-blue-800">
        <div className="max-w-7xl mx-auto py-24 px-4">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl">
              Cut Your Beverage Costs by
              <span className="block text-blue-200">30-50%</span>
            </h1>
            <p className="mt-6 max-w-2xl mx-auto text-xl text-blue-100">
              Streamline your bar or restaurant's beverage operations with our innovative inventory and distribution services.
            </p>
            <div className="mt-10">
              <button onClick={() => setCurrentPage('contact')}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-white hover:bg-blue-50">
                Get Started
                <ChevronRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto py-16 px-4">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3">
          <div className="text-center">
            <div className="flex justify-center">
              <DollarSign className="h-6 w-6 text-blue-600" />
            </div>
            <h2 className="mt-6 text-lg font-medium text-gray-900">30-50% Cost Reduction</h2>
            <p className="mt-2 text-base text-gray-500">Dramatically reduce your beverage costs through our optimized distribution network</p>
            <div className="mt-4 space-y-2 text-sm text-gray-600">
              <p>• Direct relationships with major suppliers</p>
              <p>• Bulk purchasing power across our network</p>
              <p>• Automated price optimization algorithms</p>
              <p>• Waste reduction through smart inventory</p>
            </div>
          </div>
          <div className="text-center">
            <div className="flex justify-center">
              <Wine className="h-6 w-6 text-blue-600" />
            </div>
            <h2 className="mt-6 text-lg font-medium text-gray-900">Full Inventory Management</h2>
            <p className="mt-2 text-base text-gray-500">Streamlined ordering and inventory tracking for beer, wine, and spirits</p>
            <div className="mt-4 space-y-2 text-sm text-gray-600">
              <p>• Real-time inventory tracking system</p>
              <p>• Automated reorder points</p>
              <p>• Mobile app for easy counting</p>
              <p>• Usage trend analysis and forecasting</p>
            </div>
          </div>
          <div className="text-center">
            <div className="flex justify-center">
              <Truck className="h-6 w-6 text-blue-600" />
            </div>
            <h2 className="mt-6 text-lg font-medium text-gray-900">Reliable Distribution</h2>
            <p className="mt-2 text-base text-gray-500">On-time delivery with real-time tracking and flexible scheduling</p>
            <div className="mt-4 space-y-2 text-sm text-gray-600">
              <p>• GPS-tracked delivery fleet</p>
              <p>• Flexible delivery windows</p>
              <p>• Temperature-controlled vehicles</p>
              <p>• Emergency same-day delivery available</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const AboutPage = () => (
    <div className="max-w-7xl mx-auto py-16 px-4">
      <div className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">About BevCost</h1>
        <p className="text-xl text-gray-600">Revolutionizing beverage distribution for restaurants and bars</p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
        <div>
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Our Mission</h2>
          <p className="text-gray-600 mb-6">
            We're on a mission to help restaurants and bars thrive by dramatically reducing their beverage costs while maintaining the highest quality standards in distribution and inventory management.
          </p>
          <p className="text-gray-600">
            By leveraging our extensive network and cutting-edge technology, we've helped hundreds of businesses increase their profitability without compromising on quality or service.
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Why Choose Us</h2>
          <ul className="space-y-4">
            <li className="flex items-start">
              <ChevronRight className="h-6 w-6 text-blue-600 mr-2" />
              <span className="text-gray-600">Industry-leading cost savings of 30-50%</span>
            </li>
            <li className="flex items-start">
              <ChevronRight className="h-6 w-6 text-blue-600 mr-2" />
              <span className="text-gray-600">State-of-the-art inventory management system</span>
            </li>
            <li className="flex items-start">
              <ChevronRight className="h-6 w-6 text-blue-600 mr-2" />
              <span className="text-gray-600">Reliable, on-time delivery with real-time tracking</span>
            </li>
            <li className="flex items-start">
              <ChevronRight className="h-6 w-6 text-blue-600 mr-2" />
              <span className="text-gray-600">Dedicated customer support team</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );

  const ContactPage = () => (
    <div className="max-w-7xl mx-auto py-16 px-4">
      <div className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Contact Us</h1>
        <p className="text-xl text-gray-600">Ready to reduce your beverage costs? Get in touch today.</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
        <div>
          <form className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Business Name</label>
              <input type="text" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2" />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input type="email" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2" />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Phone</label>
              <input type="tel" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2" />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Message</label>
              <textarea className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2" rows={4}></textarea>
            </div>
            <button className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700">
              Send Message
            </button>
          </form>
        </div>

        <div className="space-y-8">
          <div>
            <h2 className="text-xl font-bold text-gray-900 mb-4">Contact Information</h2>
            <div className="space-y-4">
              <div className="flex items-center">
                <Phone className="h-5 w-5 text-blue-600 mr-2" />
                <span className="text-gray-600">1-800-BEV-COST</span>
              </div>
              <div className="flex items-center">
                <Mail className="h-5 w-5 text-blue-600 mr-2" />
                <span className="text-gray-600">info@bottlecosts.com</span>
              </div>
              <div className="flex items-center">
                <MapPin className="h-5 w-5 text-blue-600 mr-2" />
                <span className="text-gray-600">123 Distribution Way, Suite 100<br />Beverly Hills, CA 90210</span>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-xl font-bold text-gray-900 mb-4">Business Hours</h2>
            <div className="space-y-2 text-gray-600">
              <p>Monday - Friday: 8:00 AM - 6:00 PM</p>
              <p>Saturday: 9:00 AM - 2:00 PM</p>
              <p>Sunday: Closed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const Footer = () => (
    <footer className="bg-gray-50 border-t">
      <div className="max-w-7xl mx-auto py-12 px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-sm font-semibold text-gray-600 tracking-wider uppercase">Contact</h3>
            <p className="mt-4 text-gray-500">
              info@bevcost.com<br />
              1-800-BEV-COST
            </p>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-gray-600 tracking-wider uppercase">Address</h3>
            <p className="mt-4 text-gray-500">
              123 Distribution Way<br />
              Suite 100<br />
              Beverly Hills, CA 90210
            </p>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-gray-600 tracking-wider uppercase">Legal</h3>
            <div className="mt-4 space-y-2">
              <a href="#" className="text-gray-500 hover:text-blue-600 block">Privacy Policy</a>
              <a href="#" className="text-gray-500 hover:text-blue-600 block">Terms of Service</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );

  return (
    <div className="min-h-screen flex flex-col">
      <Navigation />
      <main className="flex-grow">
        {currentPage === 'home' && <HomePage />}
        {currentPage === 'about' && <AboutPage />}
        {currentPage === 'contact' && <ContactPage />}
      </main>
      <Footer />
    </div>
  );
}